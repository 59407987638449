import { render, staticRenderFns } from "./Now.vue?vue&type=template&id=2dc41410&scoped=true&lang=pug&"
import script from "./Now.vue?vue&type=script&lang=js&"
export * from "./Now.vue?vue&type=script&lang=js&"
import style0 from "./Now.scss?vue&type=style&index=0&id=2dc41410&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc41410",
  null
  
)

export default component.exports