<template lang="pug">
  .root
    div(class="target-header") アンケート設定>>
      router-link(:to="{ name: 'QuestionIndex' }" :class="currentRouteName==='QuestionIndex' ? 'test-class-a' : 'test-class-b'") 新規作成
      router-link(@click.native="toNowQuestion()" to="" :class="currentRouteName==='QuestionNow' ? 'test-class-a' : 'test-class-b'") 現在のアンケート
      router-link(:to="{ name: 'QuestionHistory' }" :class="currentRouteName==='QuestionHistory' ? 'test-class-a' : 'test-class-b'") 終了済みアンケート
</template>

<style src="./Header.scss" lang="scss" scoped>
</style>

<script>
import { getQuestionListS2 } from '@/api/question'

export default {
  name: "QuestionComponentHeader",
  components: {},
  data() {
    return {
    }
  },
  props: {},
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    async toNowQuestion() {
      const ret1 = await getQuestionListS2();
      if (ret1.data.data[0]) {
        this.$router.push({ name: 'QuestionNow' })
      } else {
        this.$alert('現在のアンケートはありません。');
      }
    }
  }
};
</script>
