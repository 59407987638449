import request from '@/utils/request'

export function getQuestionListS1() {
  return request({
      url: `/questionnaires?search=stage:s1&orderBy=id&sortedBy=desc`,
      method: 'get',
  })
}

export function getQuestionListS2() {
  return request({
      url: `/questionnaires?search=stage:s2&orderBy=id&sortedBy=desc`,
      method: 'get',
  })
}

export function getQuestionListS3() {
  return request({
      url: `/questionnaires?search=stage:s3&orderBy=id&sortedBy=desc`,
      method: 'get',
  })
}

export function getQuestion(question_id) {
  return request({
      url: `/questionnaires/${question_id}`,
      method: 'get',
  })
}

export function createQuestion({ title, options, end_time, stage}) {
  return request({
      url: `/questionnaires`,
      method: 'post',
      data: {
        title,
        options,
        end_time,
        stage
      }
  })
}

export function updateQuestion({question_id, title, options, end_time, stage}) {
  return request({
      url: `/questionnaires/${question_id}`,
      method: 'put',
      data: {
        title,
        options,
        end_time,
        stage
      }
  })
}

export function closeQuestionS2({question_id, title, options, end_time}) {
  return request({
      url: `/questionnaires/${question_id}`,
      method: 'put',
      data: {
        title,
        options,
        end_time,
        stage: 's3'
      }
  })
}