<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        question-components-header

        div(class="question-title") アンケートタイトル
          span(class="caution-t") *
          input(v-model="data.title" disabled  class="input-g-w-border")

        div 内容
        div(class="question-item")
          div(class="question-item-number") 1
            span(class="caution-t") *
          input(v-model="data.options[0]" disabled class="input-g-border")
          div 合計票数 {{ count[0] }}
        div(class="question-item")
          div(class="question-item-number") 2
            span(class="caution-t") *
          input(v-model="data.options[1]" disabled class="input-g-border")
          div 合計票数 {{ count[1] }}
        div(class="question-item")
          div(class="question-item-number") 3
          input(v-model="data.options[2]" disabled class="input-g-border")
          div 合計票数 {{ count[2] }}
        div(class="question-item")
          div(class="question-item-number") 4
          input(v-model="data.options[3]" disabled class="input-g-border")
          div 合計票数 {{ count[3] }}
        div(class="question-item")
          div(class="question-item-number") 5
          input(v-model="data.options[4]" disabled class="input-g-border")
          div 合計票数 {{ count[4] }}

        div 終了日時
          span(class="caution-t") *
        input(type="datetime-local" v-model="data.end_time" disabled class="input-g-border")

        div(class="caution-t") ※＊は必須項目です

        div(class="question-bottom-button")
          img(@click="popupFinished()" class="img-button" src="/img/shuuryou_p.png")

        el-dialog(title="本当に現在のアンケートを終了してもよろしいですか?" :visible.sync="dialogVisible" width="50%" height="100%")
          el-button(type="primary" @click="onFinished()") はい
          el-button(type="secondary" @click="dialogVisible = false") いいえ

      div(class="content-lright")
        pr-banner

    component-footer-index
</template>

<style src="./Now.scss" lang="scss" scoped>
</style>

<script>
import QuestionComponentsHeader from "./components/Header";
import { getQuestionListS2, closeQuestionS2 } from '@/api/question'

export default {
  name: "QuestionNow",
  components: {
    QuestionComponentsHeader
  },
  data() {
    return {
      data: {
        question_id: undefined,
        title: '',
        options: [undefined,undefined,undefined,undefined,undefined],
        end_time: undefined,
        now_time: '',
      },
      count: [0, 0, 0, 0, 0],
      dialogVisible: false,
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const ret1 = await getQuestionListS2();
      if (ret1.data.data[0]) {
        this.data.question_id = ret1.data.data[0].id;
        this.data.title = ret1.data.data[0].title;
        this.data.options = ret1.data.data[0].options;
        this.data.end_time = this.$moment(ret1.data.data[0].end_time).format("YYYY-MM-DDTHH:mm");
        const countRet = [ret1.data.data[0].result.option[0],
          ret1.data.data[0].result.option[1],
          ret1.data.data[0].result.option[2],
          ret1.data.data[0].result.option[3],
          ret1.data.data[0].result.option[4]
        ]
        this.count = countRet.map(v => v ? v : 0);
      } else {
        window.alert('現在のアンケートはありません。');
        this.$router.push({ name: 'QuestionIndex' })
      }
    },
    popupFinished() {
      this.dialogVisible = true
    },
    async onFinished() {
      this.dialogVisible = false
      this.data.end_time = this.$moment(this.now_time).add(1, 'm').format("YYYY/MM/DD HH:mm:ss");
      await closeQuestionS2(this.data)
      this.$router.push({ name: 'QuestionHistory' })
    }
  }
};
</script>